import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import type { ThemeDefinition } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";

import DesignSystem, { type Toast } from "@tengiva/components";
import "@tengiva/components/dist/style.css";

const light: ThemeDefinition = {
	dark: false,
	colors: {
		background: "#FFFFFF",
		surface: "#FFFFFF",
		primary: "#040919",
		secondary: "#0C7CE6",
		error: "#FF0000",
		info: "#2196F3",
		success: "#72D8B4",
		warning: "#FFBA7B",
	},
};

export default defineNuxtPlugin(nuxtApp => {
	const vuetify = createVuetify({
		ssr: true,
		theme: {
			defaultTheme: "light",
			themes: {
				light,
			},
		},
		icons: {
			defaultSet: "mdi",
			sets: {
				mdi,
			},
			aliases: {
				...aliases,
				radioOff: "material-symbols-outlined md-radio-unchecked",
				radioOn: "material-symbols-outlined md-radio-checked",
			},
		},
	});

	nuxtApp.vueApp.use(vuetify).use(DesignSystem);
	return {
		provide: {
			toast: inject<Toast>("toast"),
		},
	};
});
