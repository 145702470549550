// create user related store
import { acceptHMRUpdate } from "pinia";
import type { LoginForm } from "@/composables/useURM";
import type { User } from "@/types/user";
import type { OrgProfile } from "@/types/organizations";

// Store to keep data about the user logged in (user data, organization data ...)
export const useUserStore = defineStore(
	"User",
	() => {
		const { login, logout, refreshUser } = useURM();
		const { viewOrganization } = useOrgMApi();
		// data
		const user = ref<User | null>();
		const userOrganizationProfile = ref<OrgProfile | null>(null);
		const isLoggedIn = computed(() => !!user.value);
		// async|logic
		// function for user login
		async function userLogin(data: LoginForm, sso: boolean) {
			const userInfo = await login(data, sso);
			user.value = userInfo;
			if (user.value.organization_id) {
				const { response, error } = await viewOrganization(user.value.organization_id);
				if (error) throw error;
				const res: any = response?.data;
				userOrganizationProfile.value = res?.profile as OrgProfile;
			}
			return { user: user.value, organization: userOrganizationProfile.value };
		}

		// function for user logout
		function userLogout() {
			logout();
			user.value = undefined;
		}

		// fetch user info when there is token in cookie
		// user doesn't need to login again
		async function getUserInfo() {
			user.value = await refreshUser();
			if (user.value?.organization_id) {
				const { response: orgRes } = await viewOrganization(user.value?.organization_id as string);
				const res: any = orgRes?.data;
				userOrganizationProfile.value = res?.profile as OrgProfile;
			}

			if (user.value) {
				return "Success";
			} else {
				return Promise.reject(new Error("Failed"));
			}
		}

		return {
			user,
			isLoggedIn,
			userOrganizationProfile,
			userLogin,
			userLogout,
			getUserInfo,
		};
	},
	{
		persist: {
			storage: piniaPluginPersistedstate.localStorage(),
		},
	}
);

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
