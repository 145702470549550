<template>
	<NuxtLayout>
		<NuxtLoadingIndicator />
		<NuxtPage />
	</NuxtLayout>
</template>

<script lang="ts" setup>
const { recaptchaSiteKey } = useRuntimeConfig().public;
useHead({
	script: [
		{
			src: `https://www.google.com/recaptcha/enterprise.js?render=${recaptchaSiteKey}`,
		},
	],
});
</script>

<style>
html,
body {
	font-family: "Lato";
}
</style>
