export default defineNuxtRouteMiddleware(async (to, _) => {
	// skip middleware on server
	if (import.meta.server) return;
	const orgStore = useOrgStore();
	const { organizationProfile } = storeToRefs(orgStore);
	const { $sentry } = useNuxtApp();
	if (to.path === "/auth") return;
	try {
		if (organizationProfile.value?.slug !== to.params.org_slug) {
			await orgStore.setOrganizationProfile(to.params.org_slug as string);
		}
	} catch (err) {
		$sentry.captureException(`Can not get organization profile, organization slug: ${to.params.org_slug}`);
		return navigateTo("/");
	}
	const userStore = useUserStore();

	const { getCartData } = useCartStore();
	const { user } = storeToRefs(userStore);

	const jwtToken = useCookie<string>("jwt_token");

	if (jwtToken.value) {
		if (!user.value) {
			try {
				await userStore.getUserInfo();
			} catch (error) {
				return navigateTo("/");
			}
		}
		await getCartData();
	}
});
