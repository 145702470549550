// @ts-ignore
import VueImgix from "@imgix/vue";

export default defineNuxtPlugin(nuxtApp => {
	const { imgixEcmOdcDomain } = useRuntimeConfig().public;
	nuxtApp.vueApp.use(VueImgix, {
		domain: imgixEcmOdcDomain,
		defaultIxParams: {
			// This enables the auto format imgix parameter by default for all images, which we recommend to reduce image size, but you might choose to turn this off.
			auto: "format",
		},
	});
});
